import React, { useState, useEffect } from "react";
import Header from "./sections/Header";
import Details from "./sections/Details";
import Benefits from "../team/sections/Benefits";
import { useStaticQuery, graphql } from "gatsby";
import "../../../styles/styles.css";
import ReactMarkdown from "react-markdown";

const Careers = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiCareerDetails {
        nodes {
          description
          title
          detailsList {
            buttonName
            cardId
            description
            link
            location
            slug
            positions
            title
          }
        }
      }
    }
  `);
  const {
    title,
    description,
    detailsList
  } = data.allStrapiCareerDetails.nodes[0];

  const [isJobOpenings, setIsJobOpenings] = useState(false);
  useEffect(() => {
    const openings = detailsList.some(data => data.slug === "no-jobs");
    setIsJobOpenings(!openings);
  }, [])

  return (
    <>
    {
      isJobOpenings ?
        <>
          <Header title={title} description={description} />
          <section className="section">
            <div className="container pt-0">
              <div className="row gap-y align-items-center">
                <Details detailsList={detailsList} />
              </div>
            </div>
          </section>
          <section className="section">
            <div className="row groot-zest">
              {" "}
              <div className="container">
                <div className="row gap-y align-items-center">
                  <Benefits />
                </div>
              </div>
            </div>
          </section>
        </>
        :
        <section className="no-jobs-section section pt-6">
            <div className="container no-jobs-container mt-5 d-flex justify-content-center align-items-center border">
               <div>
                  <h5>{detailsList[0].title}</h5>
                  <p><ReactMarkdown>{detailsList[0].description}</ReactMarkdown></p>
                  <div className="send-email-container">
                    <a href={detailsList[0].link} className="send-email text-center btn btn-primary btn-rounded bold px-4 py-2">
                        {detailsList[0].buttonName}
                    </a>
                  </div>
               </div>
            </div>
        </section>  
    }
    </>
  );
};

export default Careers;
