import React from "react";
import DetailCard from "../DetailCard";

const Details = ({ detailsList }) => {
  console.log(detailsList);
  return (
    <div className="col-lg-12">
      <div className="accordion accordion-clean mt-3" id="faqs-accordion">
        {detailsList.map((cardData, index) => (
          <DetailCard key={index} cardData={cardData} />
        ))}
      </div>
    </div>
  );
};

export default Details;
