import React, { useState } from "react";
import ReactMarkdown from "react-markdown";

const DetailCard = ({ cardData }) => {
  const [collapse, setCollapse] = useState(true);
  const toggleCollapse = () => {
    setCollapse((prev) => !prev);
  };
  const {
    title,
    description,
    positions,
    location,
    cardId,
    link,
    buttonName
  } = cardData;
  console.log(cardData);
  return (
    <div className="card mb-3 single-career-info">
      <div className="card-header" onClick={toggleCollapse}>
        <button
          className={`card-title btn d-flex justify-content-between ${
            collapse ? "collapsed" : ""
          }`}
          data-toggle="collapse"
          data-target={`#v1-q${cardId}`}
        >
          <h6>
            <strong>
              {title} {positions ? (`- ${positions} ${positions > 1 ? "positions" : "position"}`) : ""}
            </strong>{" "}
            ({location})
          </h6>{" "}
          <i className="fas fa-angle-down angle"></i>
        </button>
      </div>
      <div
        id={`#v1-q${cardId}`}
        className={`collapse ${collapse ? "" : "show"}`}
        data-parent="#faqs-accordion"
      >
        <div className="card-body">
          <ReactMarkdown source={description} />
          <p className="text-center">
            <a
              href={link}
              className="btn btn-primary btn-rounded mt-3 mt-md-0 ml-md-auto"
            >
              {buttonName}
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default DetailCard;
