import React from "react";
import ReactMarkdown from "react-markdown";

const Header = ({ title, description }) => {
  console.log(description)
  return (
    <section id="features" className="section careers">
      <div className="container pt-10 pb-1">
        <div className="row gap-y align-items-center">
          <div className="col-lg-12 pt-5">
            <div className="section-heading text-center">
              <h2>{title}</h2>
              <ReactMarkdown source={description} className="lead" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
