import React from "react";
import Layout from "../components/layout";
import Careers from "../components/views/careers";

const CareersPage = () => {
  return (
    <Layout>
      <Careers />
    </Layout>
  );
};

export default CareersPage;